import React from 'react';
import BookImage from '../components/images/book';
import BookDetails from '../components/book/bookDetails';
import styles from '../scss/book.module.scss';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const Book = () => {
  return (
    <div className={styles.book}>
      <Helmet defer={false}>
        <title>Saranga Publishing</title>
        <meta
          name="description"
          content="Igniting Indian Intellectual Vitality"
        />
        <meta name="image" content="../images/icon.png" />
      </Helmet>
      <section>
        <Link to="/">
          <svg
            className={styles.backIcon}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </Link>

        <BookImage className={styles.image} />
        <BookDetails />
      </section>
    </div>
  );
};

export default Book;
