import React from 'react';
import { motion } from 'framer-motion';
import styles from './bookDetails.module.scss';
import FlipkartImage from '../images/flipkart';
import AmazonImage from '../images/amazon';
import { isMobileOnly } from 'react-device-detect';

const BookDetails = () => {
  let top = -350;

  if (!isMobileOnly) top = -100;

  return (
    <motion.div
      drag="y"
      dragConstraints={{ bottom: 0, top }}
      dragElastic={0.3}
      className={styles.drag}
    >
      <div className={styles.line} />
      <p className={styles.content}>
        Ancient Yogic science deals with the physical aspects of the human body
        through the Asanas and energy aspects through various hand postures,
        called Yoga Mudras. Although the bio-electrical energy is holistically
        spread across the body, it percolates particularly through the tips of
        the fingers. Using specific finger formations these outgoing energies
        can be turned inwards to heal our body. For centuries, Mudras have been
        effective in healing people, not just physical but also mental ailments.
        Practising Mudras can be emotional as well as spiritual experience. The
        purpose of this book is to highlight effective Mudras for most common
        problems. We call them drugless, harmless healing Mudras. Dr. Rangarajan
        Iyengar brings us this wealth of knowledge that is suitable for
        practising anywhere at anytime by anyone.
      </p>
      <div className={styles.purchase}>
        <a
          className={styles.buy}
          href="https://www.amazon.in/Essential-Yoga-Mudras-Healing/dp/8195067301"
        >
          <AmazonImage />
        </a>
        <a
          className={styles.buy}
          href="https://www.flipkart.com/essential-yoga-mudras-healing/p/itmfaac78587d8af?pid=9788195067305&lid=LSTBOK9788195067305SP1HF3&marketplace=FLIPKART&q=The+Essential+Yoga+Mudras+For+Healing&store=bks&srno=s_1_1&otracker=search&otracker1=search&fm=SEARCH&iid=0fe9bf6d-fe1e-41e2-bdce-fd209189c7d4.9788195067305.SEARCH&ppt=hp&ppn=homepage&ssid=6a3rq89l6w1r18g01620183137354&qH=25d5b3fc7d0cf23a"
        >
          <FlipkartImage />
        </a>
      </div>
    </motion.div>
  );
};

export default BookDetails;
